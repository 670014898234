"use strict";

/**
 * Embedded Bookingsite Calendar Button Script
 * Copyright 2016 (c) periodic
 */
(function () {
  var loadperiodic = function loadperiodic() {
    var spinnerLocation = "https://periodic.is/imgs/loadingsquares.gif";
    var body = document.getElementsByTagName('body')[0];
    var shadowbox = document.createElement('div');
    shadowbox.setAttribute('class', 'periodic-embedded-calendar-shadowbox');

    shadowbox.onclick = function () {
      shadowbox.style.display = 'none';
    }; // must set styles explicitly for it to work in iOS


    shadowbox.style.display = 'none';
    shadowbox.style.position = 'fixed';
    shadowbox.style.top = 0;
    shadowbox.style.left = 0;
    shadowbox.style.right = 0;
    shadowbox.style.width = '100%';
    shadowbox.style.height = '100%';
    shadowbox.style.backgroundColor = 'rgba(0,0,0,.7)';
    shadowbox.style.overflow = 'hidden';
    shadowbox.style.textAlign = 'center';
    shadowbox.style.zIndex = 999999;
    var content = document.createElement('div');
    content.setAttribute('class', 'periodic-embedded-calendar-content'); // must set styles explicitly for it to work in iOS

    content.style.width = "calc(100% - 50px)";
    content.style.height = "calc(100% - 50px)";
    content.style.maxWidth = "960px";
    content.style.maxHeight = "750px";
    content.style.minWidth = "250px";
    content.style.backgroundColor = "#fff";
    content.style.backgroundImage = 'url("' + spinnerLocation + '")';
    content.style.backgroundRepeat = 'no-repeat';
    content.style.backgroundSize = '100px auto';
    content.style.backgroundPosition = '50% 50%';
    content.style.margin = '25px auto';
    content.style.position = 'relative';
    content.style.overflow = 'auto';
    content.style.WebkitOverflowScrolling = 'touch';
    var iframe = document.createElement('iframe');
    iframe.src = 'about:blank';
    iframe.scrolling = 'no';
    iframe.setAttribute('class', 'periodic-embedded-calendar-window'); // must set styles explicitly for it to work in iOS

    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";
    iframe.style.width = '1px';
    iframe.style.minWidth = '100%';
    iframe.style.height = '1px';
    iframe.style.minHeight = '100%'; // console.log(iframe.html);

    var exit_btn = document.createElement('div');
    exit_btn.setAttribute('class', 'periodic-exit-btn');
    exit_btn.innerHTML = "&times;"; // must set styles explicitly for it to work in iOS

    exit_btn.style.width = '50px';
    exit_btn.style.height = '50px';
    exit_btn.style.lineHeight = '45px';
    exit_btn.style.textAlign = 'center';
    exit_btn.style.color = '#333333';
    exit_btn.style.fontSize = '3em';
    exit_btn.style.cursor = 'pointer';
    exit_btn.style.position = 'absolute';
    exit_btn.style.left = '5px';
    exit_btn.style.top = '5px';
    exit_btn.style.borderRadius = '50%';

    exit_btn.onclick = function () {
      shadowbox.style.display = 'none';
    };

    document.onkeyup = function (event) {
      event = event || window.event;

      if (event.keyCode == 27) {
        shadowbox.style.display = 'none';
      }
    };

    content.appendChild(iframe);
    content.appendChild(exit_btn);
    shadowbox.appendChild(content);
    body.appendChild(shadowbox);

    var reshowIframe = function reshowIframe() {
      iframe.style.display = '';
    };

    var btnOnClick = function btnOnClick(event) {
      console.log('btnOnClick');
      console.log(event);
      console.log(event.target);

      if (event.target.getAttribute('periodic-url')) {
        periodic_btn = event.target;
      } else {
        periodic_btn = event.target.closest(".periodic-embedded-calendar-button");
      }

      iframe.style.display = 'none';
      iframe.src = periodic_btn.getAttribute('periodic-url');
      setTimeout(reshowIframe, 1500); // if (iframe.src !== periodic_btn.getAttribute('periodic-url')) {
      //   iframe.style.display='none';
      //   iframe.src = periodic_btn.getAttribute('periodic-url');
      //   setTimeout(reshowIframe, 1500);
      // }

      shadowbox.style.display = 'block';
    };

    var buttons = document.getElementsByClassName('periodic-embedded-calendar-button');

    for (var i = 0; i < buttons.length; i++) {
      buttons[i].onclick = btnOnClick;
    }

    var frames = document.getElementsByClassName('periodic-embedded-calendar-frame');

    for (var i = 0; i < frames.length; i++) {
      frames[i].style.minHeight = '300px';
      var inline_iframe = document.createElement('iframe'); // must set styles explicitly for it to work in iOS

      inline_iframe.style.width = "100%";
      inline_iframe.style.height = "100%";
      inline_iframe.style.border = "none";
      inline_iframe.style.width = '1px';
      inline_iframe.style.minWidth = '100%';
      inline_iframe.style.height = '1px';
      inline_iframe.style.minHeight = '100%';
      inline_iframe.setAttribute('class', 'periodic-embedded-calendar-window-inline');
      inline_iframe.src = frames[i].getAttribute('periodic-url');
      frames[i].appendChild(inline_iframe); // must set styles explicitly for it to work in iOS

      frames[i].style.backgroundColor = '#fff';
      frames[i].style.backgroundImage = 'url("' + spinnerLocation + '")';
      frames[i].style.backgroundRepeat = 'no-repeat';
      frames[i].style.backgroundSize = '100px auto';
      frames[i].style.backgroundPosition = '50% 10%';
    }

    iFrameResize({
      heightCalculationMethod: 'taggedElement',
      checkOrigin: false
    }, '.periodic-embedded-calendar-window-inline');
  };


  window.loadperiodic = loadperiodic;
  window.addEventListener('DOMContentLoaded', function () {
    loadperiodic();
  });
}).call(void 0);
